var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container", class: { editing: _vm.editing } },
    [
      !_vm.editing
        ? _c(
            "el-tag",
            {
              staticClass: "env",
              attrs: {
                "disable-transitions": true,
                size: "small",
                type: _vm.env_type,
              },
            },
            [_vm._v(" " + _vm._s(_vm.item.env) + " ")]
          )
        : _c(
            "el-select",
            {
              staticClass: "env",
              attrs: { placeholder: "Select", size: "mini" },
              model: {
                value: _vm.item.env,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "env", $$v)
                },
                expression: "item.env",
              },
            },
            _vm._l(_vm.options, function (i) {
              return _c("el-option", {
                key: i.value,
                attrs: { label: i.label, value: i.value },
              })
            }),
            1
          ),
      _c("label", [_vm._v(_vm._s(_vm.item.title) + ":")]),
      !_vm.editing
        ? _c(
            "el-tag",
            {
              staticClass: "value",
              attrs: {
                type: "info",
                "disable-transitions": true,
                size: "small",
              },
            },
            [_vm._v(" " + _vm._s(_vm.masked_credentials) + " ")]
          )
        : _c("el-input", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.toggleEdit,
                expression: "toggleEdit",
              },
            ],
            staticClass: "value",
            attrs: { size: "mini", placeholder: "Insert credentials" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.toggleEdit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.credentials,
              callback: function ($$v) {
                _vm.credentials = $$v
              },
              expression: "credentials",
            },
          }),
      !_vm.editing && _vm.mfa_token
        ? _c(
            "progress-tag",
            {
              directives: [
                {
                  name: "clipboard",
                  rawName: "v-clipboard:copy",
                  value: _vm.mfa_token,
                  expression: "mfa_token",
                  arg: "copy",
                },
              ],
              staticClass: "mfa",
              attrs: {
                type: "info",
                size: "small",
                max: 30,
                direction: "right",
                value: _vm.mfa_counter,
              },
            },
            [_vm._v(" " + _vm._s(_vm.mfa_token) + " ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.username,
              expression: "username",
              arg: "copy",
            },
          ],
          attrs: { size: "mini" },
        },
        [_vm._v("username")]
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.password,
              expression: "password",
              arg: "copy",
            },
          ],
          attrs: { size: "mini" },
        },
        [_vm._v("password")]
      ),
      _vm.show_editing
        ? _c("el-button", {
            directives: [
              { name: "only-super-admin", rawName: "v-only-super-admin" },
            ],
            attrs: {
              size: "mini",
              type: "warning",
              icon: "el-icon-edit",
              plain: "",
            },
            on: { click: _vm.toggleEdit },
          })
        : _vm._e(),
      _vm.show_editing
        ? _c("el-button", {
            directives: [
              { name: "only-super-admin", rawName: "v-only-super-admin" },
            ],
            attrs: {
              size: "mini",
              type: "danger",
              icon: "el-icon-delete",
              plain: "",
            },
            on: { click: _vm.deleteItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }