var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "recurring-container",
      class: _vm.component.status + "-background",
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "recurring-block-header",
        {
          attrs: {
            component: _vm.component,
            selected_label: _vm.getDateLabelForRecurringBlock(
              _vm.selected_block
            ),
            show_controls:
              !_vm.collapsed && !!_vm.selected_block && _vm.hide_if_highlighted,
            start: _vm.selected_start,
            end: _vm.selected_end,
            prev_enabled: !!_vm.prev_block,
            next_enabled: !!_vm.next_block,
            current_enabled: !!_vm.selected_block,
            current_label: _vm.getDateLabelForRecurringBlock(_vm.current_block),
          },
          on: {
            "prev-block": _vm.prev,
            "next-block": _vm.next,
            reset: _vm.reset,
          },
        },
        [
          _vm.selected_block && !_vm.collapsed
            ? _c(
                "template",
                { slot: "times" },
                [
                  _vm.rendered_overtime
                    ? _c("recurring-block-header-item", {
                        attrs: {
                          label: "Overtime",
                          time: _vm.rendered_overtime,
                          type: "danger",
                          sign: "+",
                        },
                      })
                    : _c("recurring-block-header-item", {
                        attrs: {
                          label: `Time ${
                            _vm.is_current_block ? "available" : "unused"
                          }`,
                          time: _vm.time_available,
                        },
                      }),
                  _c("recurring-block-header-item", {
                    attrs: {
                      label: "Time spent",
                      time: _vm.active_time_spent_reportable,
                    },
                  }),
                  _c("recurring-block-header-item", {
                    attrs: {
                      label: "Time allocated",
                      time: _vm.time_allocated,
                      calculations: _vm.time_allocated_calculations,
                      show_calculations: "",
                      exclude_zero_calculations: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.selected_block && !_vm.collapsed && _vm.hide_if_highlighted
        ? _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "bar" },
              [
                _c("bar-segment", {
                  key: "debt",
                  attrs: {
                    name: "Debt",
                    active: !!_vm.time_debt,
                    time: _vm.time_debt,
                    total_time: _vm.full_time,
                    height: 20,
                    index: 200,
                    disabled: "",
                    tooltip_prefix: "Over from last",
                    background_color: "#f92139",
                  },
                }),
                _c(
                  "bar-segment",
                  {
                    key: "spent",
                    attrs: {
                      name: "Spent",
                      background_color: "#0f263b",
                      total_time: _vm.full_time,
                      active: !!_vm.active_time_spent_reportable,
                      time: _vm.active_time_spent_reportable,
                      index: 50,
                      disable_tooltip: true,
                    },
                  },
                  [
                    _vm._l(_vm.users_time_reportable_current_block, function (
                      spent_block,
                      idk
                    ) {
                      return [
                        _vm._l(Object.entries(spent_block[1]), function (
                          user_block,
                          idx
                        ) {
                          return [
                            _c("bar-segment", {
                              key: `${spent_block[0]}-${user_block[0]}`,
                              attrs: {
                                name: "Spent",
                                active: user_block[1] > 0,
                                time: user_block[1],
                                total_time: _vm.active_time_spent_reportable,
                                height: 20,
                                index:
                                  _vm.users_time_reportable_current_block
                                    .length *
                                    50 -
                                  (idx + idk * 50),
                                border_color:
                                  (_vm.is_past ||
                                    _vm.is_future ||
                                    _vm.active_sessions.length === 0) &&
                                  idk + 1 ===
                                    _vm.users_time_reportable_current_block
                                      .length &&
                                  idx + 1 ===
                                    Object.entries(spent_block[1]).length
                                    ? ""
                                    : "#ffffff",
                                background_color: "#0f263b",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "tooltip-prefix",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "user-tooltip" },
                                          [
                                            _c("avatar", {
                                              attrs: {
                                                size: "mini",
                                                hide_tooltip: "",
                                                user: _vm.$store.getters.userWithId(
                                                  user_block[0]
                                                ),
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "time" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("milli2duration")(
                                                        user_block[1]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "date" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("dateformat")(
                                                        new Date(
                                                          spent_block[0]
                                                        ),
                                                        "DD/MM/YYYY"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        }),
                      ]
                    }),
                    _vm._l(_vm.active_sessions, function (session, idx) {
                      return _c("bar-segment", {
                        key: session.id,
                        attrs: {
                          time: _vm.timeForSession(session),
                          active: !_vm.is_past && !_vm.is_future,
                          total_time: _vm.tspent__active_sessions,
                          height: 20,
                          background_color: "#0f263b",
                          border_color:
                            idx + 1 === _vm.active_sessions.length
                              ? ""
                              : "#ffffff",
                          index: 60 - idx,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "tooltip-prefix",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "user-tooltip" },
                                    [
                                      _c("avatar", {
                                        attrs: {
                                          size: "mini",
                                          hide_tooltip: "",
                                          user: _vm.$store.getters.userWithId(
                                            session.user
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                  ],
                  2
                ),
                _c("bar-segment", {
                  key: "expiring",
                  attrs: {
                    name: "Expiring",
                    active: _vm.active_time_expiring > 0,
                    time: _vm.active_time_expiring,
                    total_time: _vm.full_time,
                    height: 20,
                    background_color: "#f5a623",
                    tooltip_prefix: "Expiring",
                    index: 40,
                  },
                }),
                _c("bar-segment", {
                  key: "rollover",
                  attrs: {
                    name: "Rollover",
                    active: _vm.active_time_rollover > 0,
                    time: _vm.active_time_rollover,
                    total_time: _vm.full_time,
                    height: 20,
                    background_color: "#80bc43",
                    tooltip_prefix: "Rollover",
                    index: 30,
                  },
                }),
                _c("bar-segment", {
                  key: "remaining",
                  attrs: {
                    name: "Remaining",
                    active: _vm.active_time_available > 0,
                    time: _vm.active_time_available,
                    total_time: _vm.full_time,
                    height: 20,
                    background_color: "#dcdfe6",
                    tooltip_prefix: _vm.is_current_block
                      ? "Available"
                      : "Unused",
                    index: 20,
                  },
                }),
                _c("bar-segment", {
                  key: "overtime",
                  attrs: {
                    name: "Overtime",
                    active: _vm.rendered_overtime > 0,
                    time: _vm.rendered_overtime,
                    total_time: _vm.full_time,
                    height: 20,
                    background_color: "#f92139",
                    index: 10,
                    tooltip_prefix: "Overtime",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.is_component_active
        ? _c("span", { staticClass: "tag" }, [
            _vm._v(" " + _vm._s(_vm.component.status) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }