<template>
    <div class="item-container">
        <el-select v-model="env" placeholder="Select" size="mini">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
        </el-select>
        <el-input
            v-model="title"
            size="mini"
            placeholder="Insert title"
            :maxlength="24"
        />
        <el-input
            v-model="value"
            size="mini"
            placeholder="Insert value"
            @keyup.enter.native="addItem"
        />
        <el-button size="mini" @click="addItem">add</el-button>
    </div>
</template>

<script>
export default {
    name: 'project-values-item-new',
    data() {
        return {
            options: [
                {
                    value: '-',
                },
                {
                    value: 'local',
                },
                {
                    value: 'staging',
                },
                {
                    value: 'production',
                },
            ],
            env: null,
            title: null,
            value: null,
        };
    },
    methods: {
        addItem() {
            if (this.env && this.title && this.value) {
                const data = {
                    env: this.env,
                    title: this.title,
                    value: this.value,
                    project: this.$fire.doc(
                        `projects/${this.$route.params.project_id}`
                    ),
                };

                this.$store.dispatch('addValues', data).then(() => {
                    this.env = null;
                    this.title = null;
                    this.value = null;
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    border-left: 1px solid $border-grey-light;
    margin-top: 20px;

    *:not(.el-button) {
        margin-right: 10px;
    }

    .env {
        width: 100px;
        text-align: center;
    }

    .el-input {
        flex: 1;
    }

    label {
        font-size: 13px;
        line-height: 20px;
        padding-top: 2px;
        margin-left: 10px;
    }

    .el-button {
        width: 100px;
    }
}
</style>
