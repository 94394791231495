var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "content-block",
        { attrs: { title: "Paths", no_background: "" } },
        [
          _c(
            "el-button",
            {
              class: { selected: _vm.is_editing_paths },
              attrs: { slot: "right", size: "mini", plain: "" },
              on: {
                click: function ($event) {
                  _vm.is_editing_paths = !_vm.is_editing_paths
                },
              },
              slot: "right",
            },
            [_vm._v(" " + _vm._s(_vm.is_editing_paths ? "Done" : "Edit") + " ")]
          ),
          !_vm.paths.length && !_vm.is_editing_paths
            ? _c("el-alert", {
                attrs: { description: "No paths saved.", closable: false },
              })
            : _vm._l(_vm.paths, function (item) {
                return _c("project-paths-item", {
                  key: item.id,
                  attrs: {
                    show_editing:
                      _vm.user_is_super_admin && _vm.is_editing_paths,
                    item: item,
                  },
                })
              }),
          _vm.is_editing_paths ? _c("ProjectPathsItem__new") : _vm._e(),
        ],
        2
      ),
      _c(
        "content-block",
        { attrs: { title: "Credentials", no_background: "" } },
        [
          _c(
            "el-button",
            {
              class: { selected: _vm.is_editing_credentials },
              attrs: { slot: "right", size: "mini", plain: "" },
              on: {
                click: function ($event) {
                  _vm.is_editing_credentials = !_vm.is_editing_credentials
                },
              },
              slot: "right",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.is_editing_credentials ? "Done" : "Edit") + " "
              ),
            ]
          ),
          !_vm.credentials.length && !_vm.is_editing_credentials
            ? _c("el-alert", {
                attrs: {
                  description: "No credentials saved.",
                  closable: false,
                },
              })
            : _vm._l(_vm.credentials, function (item) {
                return _c("project-credentials-item", {
                  key: item.id,
                  attrs: {
                    show_editing:
                      _vm.user_is_super_admin && _vm.is_editing_credentials,
                    item: item,
                  },
                })
              }),
          _vm.is_editing_credentials
            ? _c("ProjectCredentialsItem__new")
            : _vm._e(),
        ],
        2
      ),
      _c(
        "content-block",
        { attrs: { title: "Values", no_background: "" } },
        [
          _c(
            "el-button",
            {
              class: { selected: _vm.is_editing_values },
              attrs: { slot: "right", size: "mini", plain: "" },
              on: {
                click: function ($event) {
                  _vm.is_editing_values = !_vm.is_editing_values
                },
              },
              slot: "right",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.is_editing_values ? "Done" : "Edit") + " "
              ),
            ]
          ),
          !_vm.values.length && !_vm.is_editing_values
            ? _c("el-alert", {
                attrs: { description: "No values saved.", closable: false },
              })
            : _vm._l(_vm.values, function (item) {
                return _c("project-values-item", {
                  key: item.id,
                  attrs: {
                    show_editing:
                      _vm.user_is_super_admin && _vm.is_editing_values,
                    item: item,
                  },
                })
              }),
          _vm.is_editing_values ? _c("ProjectValuesItem__new") : _vm._e(),
        ],
        2
      ),
      _vm.$store.getters.is_bitbucket_authed ? _c("project-modules") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }