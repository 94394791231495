import {COMPONENT_STATUS, STAGE_STATUS} from '../enums';

const projectMixin = {
    computed: {
        // The project object for the given stage
        // ======
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },

        // All the components for the given project
        // ======
        components() {
            // Retrieve the list of component IDs from the project object.
            const components = this.project.components;
            if (!components) return [];

            return (
                components
                    // Map each component ID to the corresponding component object from the store.
                    .map((component) =>
                        this.$store.getters.componentWithId(component)
                    )
                    .filter((component) => component && component.id)
                    // Sort components so that active components come first.
                    .sort((a, b) => {
                        // Safely extract status using optional chaining, defaulting to an empty string if not available.
                        const aStatus = a?.status || '';
                        const bStatus = b?.status || '';

                        // If a is active and b is not, a should come before b.
                        if (
                            aStatus === COMPONENT_STATUS.ACTIVE &&
                            bStatus !== COMPONENT_STATUS.ACTIVE
                        )
                            return -1;
                        // If b is active and a is not, a should come after b.
                        if (
                            aStatus !== COMPONENT_STATUS.ACTIVE &&
                            bStatus === COMPONENT_STATUS.ACTIVE
                        )
                            return 1;
                        // Otherwise, maintain their relative order.
                        return 0;
                    })
            );
        },

        // All the non-recurring components for the given project
        // ======
        non_recurring_components() {
            return this.components.filter((c) => !c.recurring);
        },

        // All the recurring components for the given project
        // ======
        recurring_components() {
            return this.components.filter((c) => c.recurring);
        },
    },
};

export default projectMixin;
