<template>
    <div class="item-container" :class="{editing: editing}">
        <el-tag
            v-if="!editing"
            :disable-transitions="true"
            size="small"
            class="env"
            :type="env_type"
        >
            {{ item.env }}
        </el-tag>

        <el-select
            v-else
            v-model="item.env"
            placeholder="Select"
            size="mini"
            class="env"
        >
            <el-option
                v-for="i in options"
                :key="i.value"
                :label="i.label"
                :value="i.value"
            >
            </el-option>
        </el-select>

        <label>{{ item.title }}:</label>

        <el-tag
            v-if="!editing"
            type="info"
            :disable-transitions="true"
            size="small"
            class="value"
        >
            {{ masked_credentials }}
        </el-tag>

        <el-input
            v-else
            v-model="credentials"
            v-on-clickaway="toggleEdit"
            size="mini"
            placeholder="Insert credentials"
            class="value"
            @keyup.enter.native="toggleEdit"
        />

        <progress-tag
            v-if="!editing && mfa_token"
            v-clipboard:copy="mfa_token"
            type="info"
            size="small"
            class="mfa"
            :max="30"
            direction="right"
            :value="mfa_counter"
        >
            {{ mfa_token }}
        </progress-tag>

        <el-button v-clipboard:copy="username" size="mini">username</el-button>
        <el-button v-clipboard:copy="password" size="mini">password</el-button>

        <el-button
            v-if="show_editing"
            v-only-super-admin
            size="mini"
            type="warning"
            icon="el-icon-edit"
            plain
            @click="toggleEdit"
        />
        <el-button
            v-if="show_editing"
            v-only-super-admin
            size="mini"
            type="danger"
            icon="el-icon-delete"
            plain
            @click="deleteItem"
        />
    </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

import * as twofactor from 'node-2fa';
import dayjs from 'dayjs';

import ProgressTag from '@/components/generic/ProgressTag';

export default {
    name: 'project-credentials-item',
    components: {ProgressTag},
    mixins: [clickaway],
    props: {
        item: {
            type: Object,
            required: true,
        },
        show_editing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [
                {value: '-'},
                {value: 'local'},
                {value: 'staging'},
                {value: 'production'},
            ],
            editing: false,
            credentials: null,
            mfa_token: null,
            mfa_counter: 0,
            mfa_interval: null,
        };
    },
    computed: {
        env_type() {
            switch (this.item.env) {
                case 'production':
                    return 'danger';
                case 'staging':
                    return '';
                default:
                    return 'info';
            }
        },
        username() {
            return this.item.credentials.split(':')[0];
        },
        password() {
            return this.item.credentials.split(':')[1];
        },
        mfa_key() {
            return this.item.credentials.split(':')[2];
        },
        masked_credentials() {
            return this.username + ' / ' + '•••••••••••';
        },
    },
    watch: {
        credentials: function (val) {
            this.$fire
                .collection('credentials')
                .doc(this.item.id)
                .update({credentials: val ? val.replace(/\n$/, '') : null});
        },
        mfa_key(newVal, oldVal) {
            if (!newVal) {
                // remove 2FA code display
                clearInterval(this.mfa_interval);
                this.mfa_interval = null;
                this.mfa_token = null;
            } else if (newVal != oldVal) {
                this.initMFA();
            }
        },
    },
    created() {
        this.initMFA();
    },
    beforeDestroy() {
        clearInterval(this.mfa_interval);
    },
    methods: {
        initMFA() {
            if (this.mfa_key) {
                if (!this.mfa_interval) {
                    // if this credential has a 2FA key set & timer is not yet running, start timer
                    // get time to next token
                    this.createMFAToken();
                    this.mfa_interval = setInterval(() => {
                        this.mfa_counter -= 1;
                        if (this.mfa_counter < 0) {
                            this.createMFAToken();
                        }
                    }, 1000);
                } else {
                    // otherwise just regen the token
                    this.createMFAToken();
                }
            }
        },
        createMFAToken() {
            const now = dayjs();
            this.mfa_counter = 30 - (now.second() % 30); // seconds until next token
            this.mfa_token = twofactor.generateToken(this.mfa_key).token;
        },
        toggleEdit() {
            this.credentials = this.item.credentials;
            this.editing = !this.editing;
        },
        deleteItem() {
            this.$fire.collection('credentials').doc(this.item.id).delete();
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    border-left: 1px solid $border-grey-light;

    *:not(.el-button) {
        margin-right: 10px;
    }

    .env {
        width: 100px;
        text-align: center;
    }

    .value {
        height: 28px;
        line-height: 28px;
        flex: 1;
        width: 100%;
        flex: 1;
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mfa {
        display: flex;
        cursor: pointer;
        ::v-deep .tag {
            height: 28px;
            width: 80px;
            text-align: center;
            line-height: 28px;
            user-select: none;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .el-input {
        flex: 1;
    }

    label {
        font-size: 13px;
        line-height: 20px;
        padding-top: 2px;
        margin-left: 10px;
        width: 250px;
        text-transform: capitalize;
    }
}
</style>
