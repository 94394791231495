<template>
    <div class="item-container">
        <el-tag
            v-if="!editing"
            :disable-transitions="true"
            size="small"
            class="env"
            :type="env_type"
        >
            {{ item.env }}
        </el-tag>
        <el-select
            v-else
            v-model="item.env"
            placeholder="Select"
            size="mini"
            class="env"
        >
            <el-option
                v-for="i in options"
                :key="i.value"
                :label="i.label"
                :value="i.value"
            >
            </el-option>
        </el-select>

        <label>{{ item.title }}:</label>

        <a v-if="!editing" :href="item.value" target="_blank">
            <el-tag
                type="info"
                :disable-transitions="true"
                size="small"
                class="value"
            >
                {{ item.value }}
            </el-tag>
        </a>
        <el-input
            v-else
            v-model="value"
            v-on-clickaway="toggleEdit"
            size="mini"
            placeholder="Insert path"
            @keyup.enter.native="toggleEdit"
        />

        <el-button v-clipboard:copy="item.value" size="mini">path</el-button>

        <el-button
            v-if="show_editing"
            v-only-super-admin
            size="mini"
            type="warning"
            icon="el-icon-edit"
            plain
            @click="toggleEdit"
        />
        <el-button
            v-if="show_editing"
            v-only-super-admin
            size="mini"
            type="danger"
            icon="el-icon-delete"
            plain
            @click="deleteItem"
        />
    </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

export default {
    name: 'project-paths-item',
    mixins: [clickaway],
    props: {
        item: {
            type: Object,
            required: true,
        },
        show_editing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editing: false,
            value: null,
            options: [
                {value: '-'},
                {value: 'local'},
                {value: 'staging'},
                {value: 'production'},
            ],
        };
    },
    computed: {
        env_type() {
            switch (this.item.env) {
                case 'production':
                    return 'danger';
                case 'staging':
                    return '';
                default:
                    return 'info';
            }
        },
    },
    methods: {
        toggleEdit() {
            this.editing = !this.editing;

            // Finished editing
            if (!this.editing && this.value !== this.item.value) {
                this.$fire
                    .collection('paths')
                    .doc(this.item.id)
                    .update({
                        value: this.value
                            ? this.value.replace(/\n$/, '')
                            : null,
                    });
            }

            this.value = this.item.value;
        },
        deleteItem() {
            this.$fire.collection('paths').doc(this.item.id).delete();

            this.$fire
                .collection('paths')
                .doc(this.item.id)
                .update({
                    value: this.value ? this.value.replace(/\n$/, '') : null,
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    border-left: 1px solid $border-grey-light;

    *:not(.el-button) {
        margin-right: 10px;
    }

    .env {
        min-width: 100px;
        text-align: center;
    }

    .el-select {
        max-width: 100px;
    }

    a {
        flex: 1;
        height: 28px;
        max-width: calc(100% - 420px);

        .value {
            height: 28px;
            line-height: 28px;
            flex: 1;
            width: 100%;
            user-select: none;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .el-input {
        flex: 1;
    }

    label {
        font-size: 13px;
        line-height: 20px;
        padding-top: 2px;
        margin-left: 10px;
        width: 250px;
        text-transform: capitalize;
    }
}
</style>
